<template>
    <v-container fluid>
        <v-container fluid>
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems"></dmx-title>
            </b-col>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-btn icon
                           light
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h1>{{ logLevel.name }}: {{ shortMessage }}</h1>
                </v-col>
            </v-row>
        </v-container>

        <v-card color="demex_yellow">
            <!--<v-toolbar dense
                       max-height="50"
                       :color="logLevel.background"
                       :dark="logLevel.dark">
            
            </v-toolbar>-->
            <v-card-text class="mt-3">
                {{ createdOnUtc }}<br /><br />

                <b>{{ $t('common_message') }}</b>:<br />
                <v-textarea name="fullMessageTextAre"
                            filled
                            disabled
                            auto-grow
                            v-model="fullMessage">

                </v-textarea>


                <b>{{ $t('common_url') }}</b>: <a :href="pageUrl">{{ pageUrl }}</a><br />
                <b>{{ $t('common_referrer') }}</b>: {{ referrerUrl }}<br />
                <b>{{ $t('common_ip') }}</b>: {{ ipAddress }}<br /><br />
            </v-card-text>
            <div style="flex: 1 1 auto;"></div>
        </v-card>
    </v-container>
</template>

<script>
    import logService from '@/services/logService';
    import router from '@/router';
    import { logLevels } from '../../variables/variables.js';
    import moment from 'moment';

    function truncate(str, n) {
        return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    };

    export default {
        name: 'ViewLogEntry',
        props: ["logId"],
        components: {
        },
        data: () => ({
            createdOnUtc: '',
            logLevel: '',
            shortMessage: '',
            fullMessage: '',
            pageUrl: '',
            referrerUrl: '',
            ipAddress: '',
        }),
        methods: {
            renderData(data) {
                if (data) {
                    this.createdOnUtc = moment(data.createdOnUtc).format('YYYY-MM-DD HH:mm:SS');
                    this.logLevel = logLevels[data.logLevel];
                    this.shortMessage = truncate(data.shortMessage, 80);
                    this.fullMessage = data.fullMessage;
                    this.pageUrl = data.pageUrl;
                    this.referrerUrl = data.referrerUrl;
                    this.ipAddress = data.ipAddress;
                }
            }
        },
        computed: {
            bcItems() {
                return [
                    {
                        text: 'log',
                        href: '/log',
                    },
                    {
                        text: this.shortMessage,
                        href: '/log/viewLogEntry/' + this.logId,
                        active: true,
                    }
                ]
            },
            },
        mounted() {
            if (this.logId > 0) {
                logService.getLogEntry(this.logId)
                    .then(data => {
                        this.renderData(data);
                    });
            }
        }
    }
</script>
